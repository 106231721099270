import * as React from 'react';
import { styled } from '../../../Layout/ThemeProvider/ThemeProvider';

const ArticleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 34.5rem;
  width: 100%;
  margin-bottom: 1.6rem;
  border-radius: .4rem;
  text-decoration: none;
  background-image: url(${(props: { backgroundImageUrl: string }) => props.backgroundImageUrl});
  background-size: cover;
  background-position: center;
  cursor: pointer;

  @media (${props => props.theme.deviceBreakpoints.laptopS}) {
    height: 33rem;
    width: 54.5rem;
    margin-left: 1.6rem;
  }

  &:hover {
    &:after {
      background: linear-gradient(180deg, rgba(23,40,66,0) 16.5%, rgba(1,2,3,.65) 69.5%, rgba(0,0,0,.65) 100%);
    }

    & > div > h3 {
      text-decoration: underline;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: .4rem;
    background: linear-gradient(180deg, rgba(23,40,66,0) 16.5%, rgba(1,2,3,.45) 69.5%, rgba(0,0,0,.45) 100%);
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2.4rem;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

const CategoriesContainer = styled.div``;

const CategoryLabel = styled.span`
  display: inline-block;
  height: 1.8rem;
  margin-bottom: .8rem;
  margin-right: .25rem;
  padding: .4rem .6rem;
  border-radius: .3rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary8};
  text-transform: uppercase;
`;

const DateAndAuthorContainer = styled.div`
  display: block;
  font-family: ${({ theme }) => theme.fonts.quicksandMedium}, sans-serif;
  font-size: 1.4rem;

  span:first-of-type {
    position: relative;
    margin-right: 1.4rem;

    &:after {
      content: '';
      position: absolute;
      top: .7rem;
      left: 108%;
      height: .3rem;
      width: .3rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const ArticleTitle = styled.h3`
  margin: 0;
  padding 0;
  font-family: ${({ theme }) => theme.fonts.quicksandMedium}, sans-serif;
  font-size: 2.4rem;
  line-height: 2.8rem;
`;

const renderCategories = (categories: string[]) => categories.map((category, idx) => (
  <CategoryLabel key={idx}>
    {category}
  </CategoryLabel>
));

type ArticleDescriptionProps = {
  title: string;
  date: string;
  author: string;
  showCategories?: boolean;
  categories?: string[];
};

type ArticleTileLargeProps = ArticleDescriptionProps & {
  imageUrl: string;
  link: string;
};

const ArticleDescription: React.FC<ArticleDescriptionProps> = props => (
  <DescriptionContainer>
    {props.showCategories && props.categories && (
      <CategoriesContainer>
        {renderCategories(props.categories)}
      </CategoriesContainer>
    )}
    <DateAndAuthorContainer>
      <span>{props.date}</span>
      <span>{props.author}</span>
    </DateAndAuthorContainer>
    <ArticleTitle>
      {props.title}
    </ArticleTitle>
  </DescriptionContainer>
);

export const ArticleTileLarge: React.FC<ArticleTileLargeProps> = props => {
  const { link, imageUrl, ...articleDescriptionProps } = props;

  return (
    <a
      href={props.link}
      style={{ textDecoration: 'none' }}
    >
      <ArticleContainer
        backgroundImageUrl={props.imageUrl}
      >
        <ArticleDescription
          {...articleDescriptionProps}
        />
      </ArticleContainer>
    </a>
  );
};
