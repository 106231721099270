import * as React from 'react';
import { styled } from '../../../Layout/ThemeProvider/ThemeProvider';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.6rem;

  @media (${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    margin-bottom: 3.2rem;
  }
`;

const RightLink = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;

  font-family: ${({ theme }) => theme.fonts.quicksandBold}, sans-serif;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.primary1};
  margin-right: .2rem;

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary3};

    &:after {
      border-color: ${({ theme }) => theme.colors.primary3};
    }
  }

  &:after {
    content: '';
    height: .6rem;
    width: .6rem;
    margin-left: .5rem;
    border-right: .2rem solid ${({ theme }) => theme.colors.primary1};
    border-top: .2rem solid ${({ theme }) => theme.colors.primary1};
    transform: rotate(45deg);
  }
`;

const Title = styled.h3`
  padding 0;
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.quicksandBold}, sans-serif;
  color: ${({ theme }) => theme.colors.secondary13};
  font-size: 2.4rem;
  line-height: 3.4rem;

  @media (${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    font-size: 3.4rem;
    line-height: 4.8rem;
  }
`;

export type ArticlesSectionHeaderProps = {
  title: string;
  showLink?: boolean;
  link?: string;
  linkLabel?: string;
};

export const ArticlesSectionHeader: React.FC<ArticlesSectionHeaderProps> = props => {
  const { title, link, linkLabel, showLink } = props;

  return (
    <Header>
      <Title>
        {title}
      </Title>
      {showLink && link && linkLabel && (
        <RightLink
          href={link}
        >
          {linkLabel}
        </RightLink>
      )}
    </Header>
  );
};
