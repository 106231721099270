import * as React from 'react';
import { styled } from '../../../Layout/ThemeProvider/ThemeProvider';
import { ArticlesSectionHeader, ArticlesSectionHeaderProps } from '../ArticlesSectionHeader/ArticlesSectionHeader';
import { ArticleTile, ArticleTileProps } from '../ArticleTile/ArticleTile';
import { ArticleTileLarge } from '../ArticleTile/ArticleTileLarge';

const ArticlesSectionContainer = styled.div`
  display: flex;
  align-self: center;

  flex-direction: column;
  width: 54rem;
  padding: 0 1.6rem;

  @media(${({ theme }) => theme.deviceBreakpoints.tabletL}) {
    width: 96rem;
  }

  @media(${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    width: 114rem;
  }
`;

const SmallArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media(${({ theme }) => theme.deviceBreakpoints.tabletL}) {
    flex-direction: row;
    margin-left: -1.6rem;
  }

  @media(${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    display: contents;
  }
`;

const ArticlesContainer = styled.div`
  @media(${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.6rem;
  }
`;

type ArticleOmitted = Omit<ArticleTileProps, 'showDetails' | 'showCategories'>;

type Props = ArticlesSectionHeaderProps & {
  articles: ArticleOmitted[];
  showDetails?: boolean;
  showCategories?: boolean;
};

export const ArticlesSection: React.FC<Props> = props => {
  const { title, showLink, link, linkLabel, articles, showDetails, showCategories } = props;
  const [firstArticleData, ...restArticlesData] = articles;

  return (
    <ArticlesSectionContainer>
      <ArticlesSectionHeader
        title={title}
        showLink={showLink}
        link={link}
        linkLabel={linkLabel}
      />
      <ArticlesContainer>
        <ArticleTileLarge
          {...firstArticleData}
          showCategories={showCategories}
        />
        <SmallArticlesContainer>
          {restArticlesData.map((article, idx) => (
            <ArticleTile
              key={`${article.title}_${idx}`}
              {...article}
              showDetails={showDetails}
              showCategories={showCategories}
            />
          ))}
        </SmallArticlesContainer>
      </ArticlesContainer>
    </ArticlesSectionContainer>
  );
};
