import { styled } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import * as React from 'react';
import { Logo } from '../Logo/Logo';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BigLogo = styled(Logo)`
  width: 400px !important;
`;

export const Placeholder = () => {
  return (
    <Container>
      <a href="https://app.fakturuj.to/">
        <BigLogo/>
      </a>
    </Container>
  );
};
