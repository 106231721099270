import { ArticlesSection } from 'components/src/components/Elements/Article/ArticlesSection/ArticlesSection';
import { styled, Theme, ThemeProvider } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';
import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import Header from '../../components/Header/Header';
import { Placeholder } from '../../components/Placeholder/Placeholder';

type RenderArticlesSectionProps = {
  articles: any[];
};

const Container = styled.div`
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
`;

const RenderArticlesSection: React.FC<RenderArticlesSectionProps> = props => {
  const { queryBreakpoints: { tabletL, laptopS, laptop, desktop } } = React.useContext<Theme>(ThemeContext);

  return (
    <ArticlesSection
      showLink
      showCategories
      showDetails={tabletL || laptopS || laptop || desktop}
      title=""
      articles={props.articles}
    />
  );
};

type Props = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          id: string;
          fields: {
            slug: string;
          },
          frontmatter: {
            title: string;
            templateKey: string;
            date: string;
            blog_roll_img: string;
            topics: { label: string }[];
            author: string;
          }
        }
      }[]
    }
  }
}

class BlogRoll extends React.Component<Props> {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const articles = posts.map(({ node: { fields, frontmatter, excerpt, id } }) => ({
      link: fields.slug,
      imageUrl: frontmatter.blog_roll_img,
      articleExcerpt: excerpt,
      categories: frontmatter.topics.map(topic => topic.label),
      date: frontmatter.date,
      title: frontmatter.title,
      author: frontmatter.author,
    }));

    return (
      <ThemeProvider
        render={() => (
          <Placeholder/>
          // <Container>
          //   {(articles.length && (
          //     <RenderArticlesSection
          //       articles={articles}
          //     />
          //   ))}
          // </Container>
        )}
      />
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "article" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                author
                templateKey
                date(formatString: "DD.MM.YYYY")
                blog_roll_img
                topics {
                  label
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <BlogRoll data={data}/>}
  />
)
