import * as React from 'react';
import { styled } from '../../../Layout/ThemeProvider/ThemeProvider';

const ArticleTileContainer = styled.div`
  margin-bottom: 2rem;

  @media (${({ theme }) => theme.deviceBreakpoints.tabletL}) {
    width: 31.6%;
    margin-left: 1.6rem;
  }

  @media (${({ theme }) => theme.deviceBreakpoints.laptopS}) {
    width: 23.5%;
    margin-bottom: 1.6rem;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8.2rem;
  overflow: hidden;

  @media (${({ theme }) => theme.deviceBreakpoints.tabletL}) {
    flex-direction: column;
    height: 33rem;
  }

  &:hover {
    & > img {
      opacity: .9;
    }

    h3 {
      color: ${({ theme }) => theme.colors.blue2};
    }
  }
`;

const ArticleImg = styled.img`
  width: 10.2rem;
  border-radius: .4rem;
  margin-right: 1.2rem;

  @media (${({ theme }) => theme.deviceBreakpoints.tabletL}) {
    width: 100%;
    height: 15.7rem;
    margin-right: 0;
    margin-bottom: 1.2rem;
  }
`;

const ArticleDescription = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.quicksandMedium}, sans-serif;
  text-rendering: optimizeSpeed;
  color: ${({ theme }) => theme.colors.secondary9};
`;

const CategoriesContainer = styled.div`
  line-height: 1.1rem;
  margin-bottom: .4rem;
`;

const CategoryLabel = styled.span`
  display: inline-block;
  height: unset;
  margin-right: .8rem;
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.quicksandBold}, sans-serif;
  color: ${({ theme }) => theme.colors.primary14};
  text-transform: uppercase;
`;

const DateAndAuthorContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.secondary9};

  span:first-of-type {
    position: relative;
    margin-right: 1.4rem;

    &:after {
      content: '';
      position: absolute;
      top: .7rem;
      left: 108%;
      height: .3rem;
      width: .3rem;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.secondary9};
    }
  }
`;

const ArticleTitle = styled.h3`
  margin: 0;
  padding 0;
  color: ${({ theme }) => theme.colors.withoutDesign5};
  font-size: 1.6rem;
  line-height: 2.2rem;
`;

const ArticleExcerpt = styled.p`
  margin: 0;
  padding: 0;
  line-height: 2rem;
`;

const renderCategories = (categories: string[]) => categories.map((category, idx) => (
  <CategoryLabel key={idx}>
    {category}
  </CategoryLabel>
));

type ArticleDescriptionProps = {
  title: string;
  date: string;
  author: string;
  articleExcerpt?: string;
  showDetails?: boolean;
  showCategories?: boolean;
  categories?: string[];
};

export type ArticleTileProps = ArticleDescriptionProps & {
  imageUrl: string;
  imageAlt?: string;
  link: string;
};

export const ArticleTile: React.FC<ArticleTileProps> = props => (
  <ArticleTileContainer>
    <a
      href={props.link}
      style={{ textDecoration: 'none' }}
    >
      <ArticleContainer>
        <ArticleImg
          src={props.imageUrl}
          alt={props.imageAlt}
        />
        <ArticleDescription>
          {props.showCategories && props.categories && (
            <CategoriesContainer>
              {renderCategories(props.categories)}
            </CategoriesContainer>
          )}
          {props.showDetails && (
            <DateAndAuthorContainer>
              <span>{props.date}</span>
              <span>{props.author}</span>
            </DateAndAuthorContainer>
          )}
          <ArticleTitle>
            {props.title}
          </ArticleTitle>
          {props.showDetails && (
            <ArticleExcerpt>
              {props.articleExcerpt}
            </ArticleExcerpt>
          )}
        </ArticleDescription>
      </ArticleContainer>
    </a>
  </ArticleTileContainer>
);
